
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ContactFormService } from '../../services/contact-form.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {

  contactForm!: FormGroup;

  constructor(
    private service: ContactFormService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['',
        [
          Validators.required,
          Validators.email
        ]
      ],
      phone: [''],
      message: ['']
    });
  }

  // submitForm(){}

    submitForm(){
        this.service.sendContactEmail(this.contactForm.getRawValue()).subscribe( {
        next: (response) =>{
            Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Mensagem enviada com sucesso!',
            text: 'Seu formulário foi enviado com sucesso! Em breve iremos entrar em contato. Obrigado !',
            showConfirmButton: false,
            timer: 1500
            })
            this.contactForm.reset();
            // console.log('certo');
        },
        error: (e) => console.error(e),
        complete: () => console.info('complete')
        })
}

}

