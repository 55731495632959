<div class="root">
  <app-nav></app-nav>
  <div class="body">
    <router-outlet/>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>

