import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss'
})
export class NavComponent {

  nav: boolean = false;

  constructor( public router: Router ) { }

  openNav() {
    const icon = document.getElementById('iconMobile') as HTMLImageElement;
    const contentNav = document.getElementsByClassName('actions')[0] as HTMLElement;
    const nav = document.getElementsByClassName('nav')[0] as HTMLElement;
    const body = document.getElementsByTagName('body')[0] as HTMLElement;
    this.nav =! this.nav;

    if (this.nav) {
      icon.src = './assets/icons/i-nav-close.svg';
      body.style.overflow = 'hidden';
      contentNav.style.display = 'flex';
      nav.style.height = "100%";
    } else {
      icon.src = './assets/icons/i-nav.svg';
      body.style.overflow = 'auto';
      contentNav.style.display = 'none';
      nav.style.height = "auto";
    }
  }

  public smooth(id: string, home?: boolean) {

    this.router.navigate(['/home']);

    setTimeout(() => {
      const idx = document.getElementById(id) as HTMLElement;
      idx.scrollIntoView({behavior:"smooth"});
    }, 200);

    if (home != true) {
      const view = screen.width;
      if (view <= 1024) {
        this.openNav();
      } 
    }
    
  }

}
