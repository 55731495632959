
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class ContactFormService {

    constructor(private http: HttpClient) { }

    sendContactEmail(data: any): Observable<any> {

        return this.http.post<any>('https://formsubmit.co/ajax/comercial@multiversoonline.com.br', data)
        .pipe(map(response => {
            return response;
        }));
    }


}
