<div class="contact">
    <div class="contatc-txt">
        <span class="title-txt">Vamos conversar!</span>
        <img class="bg-contact" src="./assets/backgrouns/bg-contact.png" alt="">
    </div>
    <div class="form">
        <form [formGroup]="contactForm" (submit)="submitForm()">
            <div class="input-field">
                <input type="text" formControlName="name" id="name" placeholder="">
                <label for="name" class="input-label">Nome</label>
            </div>
            <div class="input-field">
                <input type="text" formControlName="email" id="name" placeholder="">
                <label for="email" class="input-label">Email</label>
            </div>
            <div class="input-field">
                <input type="text" formControlName="phone" id="name" placeholder="">
                <label for="phone" class="input-label">Telefone</label>
            </div>
            <div class="input-field">
                <textarea formControlName="message" placeholder=""></textarea>
                <label for="message" class="input-label textarea">Mensagem</label>
            </div>
            <button type="submit"  class="btn-multiverso yellow">
                Enviar
            </button>
        </form>
    </div>
</div>
