import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  changeIcon(type:string) {
    const iconW = document.getElementById('iconWhatsapp') as HTMLImageElement;
    const iconI = document.getElementById('iconInstagram') as HTMLImageElement;
    const iconf = document.getElementById('iconFacebook') as HTMLImageElement;

    switch (type) {
      case 'enterW':
        iconW.src =  './assets/icons/i-whatsapp-color.svg'
        break;
      case 'leaveW':
        iconW.src =  './assets/icons/i-whatsapp.svg'
        break;
      case 'enterI':
        iconI.src =  './assets/icons/i-instagram-color.svg'
        break;
      case 'leaveI':
        iconI.src =  './assets/icons/i-instagram.svg'
        break;
      case 'enterF':
        iconf.src =  './assets/icons/i-facebook-color.svg'
        break;
      case 'leaveF':
          iconf.src =  './assets/icons/i-facebook.svg'
          break;
    
      default:
        break;
    }
  }

}
