<div class="nav">
    <div class="logo">
        <img class="lg-multiverso" src="./assets/images/lg-multiverso-nav.png" alt="logo-multiverso" (click)="smooth('home', true)">
        <img class="nav-mobile" id="iconMobile" src="./assets/icons/i-nav.svg" alt="" (click)="openNav()">
    </div>
    <div class="actions">
        <ul>
            <li>
                <a (click)="smooth('home')">Inicio</a>
            </li>
            <li>
                <a (click)="smooth('about')">Sobre</a>
            </li>
            <li>
                <a (click)="smooth('solutions')" >Soluções</a>
            </li>
            <li>
                <a (click)="smooth('technologies')" >Tecnologias</a>
            </li>
        </ul>
        <button class="btn-multiverso yellow desktop" [routerLink]="['/contato']">
            Entre em contato
        </button>
        <button class="btn-multiverso yellow mobile" [routerLink]="['/contato']" (click)="openNav()">
            Entre em contato
        </button>
    </div>
</div>
