<!-- 1° scroll -->
<section >
    <div class="home" id="home">
        <img class="bg1" src="./assets/backgrouns/bg-home1.png" alt="">
        <div class="text">
            <div class="title-txt">
                Você precisa de uma LandingPage ou gostaria de dar um up no layout do seu site ou sitema? Estamos aqui para te ajudar!
            </div>
            <div class="description-txt">
                Você precisa de um layout ou até mesmo criar uma marca? Você ja tem um layout e procura por alguém que possa te ajudar a tirar sua ideia do papel? Ou você procura ambos, adivinha. Podemos te ajudar.
            </div>
            <button class="btn-multiverso" (click)="smooth('about')">
                Conheça mais sobre a Multiverso
                <img src="./assets/icons/i-arrow-down.svg" alt="icon-arrow">
            </button>
        </div>
        <div class="logo">
            <img src="./assets/logos/lg-home.png" alt="logo-multiverso">
        </div>
    </div>
</section>
<!-- 2° scroll -->
<section >
    <div class="about" id="about">
        <img src="./assets/icons/i-multiverso.svg" alt="lg-multiverso">
        <div class="description-txt fade">
            A <span class="emphasis yellow">Multiverso</span> nasceu com o propósito de <span class="emphasis blue">ajudar as empresas</span> a se comunicar com seu cliente de maneira efetiva e direta por meio da tecnologia, garantindo um <span class="emphasis blue">visual bonito, intuitivo e agradável</span> ao seu usuário.
        </div>
    </div>
</section>
<!-- 3° scroll -->
<section >
    <div class="solutions" id="solutions">
        <span class="title-txt">Como podemos te ajudar</span>
        <div class="box-solutions">
            <img src="./assets/icons/i-website-development.svg" alt="icon-website" class="fadeLeft">
            <div class="box-description fade">
                <span class="title-txt"> Desenvolvimento de sites </span>
                <span class="description-txt emphasis blue">
                    Mostre que você existe, exponha sua marca/empresa para o mundo.
                </span>
                <span class="description-txt">
                    Criamos um site professional de acordo com as necessidades do seu negocio , sites institucionais e sites corporativos.
                </span>
            </div>
        </div>
        <div class="box-solutions reverse">
            <div class="box-description fade">
                <span class="title-txt"> Desenvolvimento de Landing Pages </span>
                <span class="description-txt emphasis blue">
                    Página única e dedicada com intuito de gerar leads ou conversão de algum produto.
                </span>
                <span class="description-txt">
                    Criamos Landing Pages envolventes com foco em conversão de leads e vendas, criando uma estrutura de captação robusta em um site professional.
                </span>
            </div>
            <img src="./assets/icons/i-landing-page-development.svg" alt="icon-landing-page" class="fadeRight">
        </div>
        <div class="box-solutions">
            <img src="./assets/icons/i-systems-development.svg" alt="icon-systems-development" class="fadeLeft">
            <div class="box-description fade">
                <span class="title-txt"> Desenvolvimento de sistemas </span>
                <span class="description-txt emphasis blue">
                    Intra/extranet, sistemas para usuários internos ou externos, geralmente necessita de cadastro e login.
                </span>
                <span class="description-txt">
                    Criamos sistemas personalizados feitos especialmente para sua empresa atendendo sua necessidades e padrões.
                </span>
            </div>
        </div>
    </div>
</section>
<!-- 4° scroll -->
<section >
    <div class="technologies" id="technologies">
        <span class="title-txt">Tecnologias que utilizamos</span>
        <div class="box-align">
            <div class="box-technologies">
                <span class="title-txt">Landing Pages</span>
                <div class="tech-align">
                    <img src="./assets/icons/i-html.svg" alt="icon-html">
                    <img src="./assets/icons/i-css.svg" alt="con-css">
                    <img src="./assets/icons/i-javascript.svg" alt="icon-javascript">
                </div>
            </div>
            <div class="box-technologies">
                <span class="title-txt">Sites e Sistemas</span>
                <div class="tech-align">
                    <img src="./assets/icons/i-angular.svg" alt="icon-angular">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 5° scroll -->
<section>
    <div class="contact">
        <span class="title-txt">
            Vamos tomar um café?<br/> Vamos bater um papo sem compromisso e discutimos o <span class="emphasis yellow">futuro da sua empresa!</span>
        </span>
        <button class="btn-multiverso yellow" [routerLink]="['/contato']">
            Entre em contato
        </button>
    </div>
</section>
