<div class="footer">
    <img class="bg-footer" src="./assets/backgrouns/bg-footer.png" alt="">
    <div class="box-logo">
        <div class="logo">
            <img src="./assets/logos/lg-footer.png" alt="logo-multiverso">
            O universo é muito grande, onde você está?
        </div>
        <div class="copyright">
            <span>&#169;</span> 2024  Todos os direitos reservados - Multiverso Online
        </div>
    </div>
    <div class="box-media">
        <a href="https://api.whatsapp.com/send?phone=5511986452592&text=Podemos%20agendar%20um%20bate%20papo?%20" target="_blank">
            <img src="./assets/icons/i-whatsapp.svg" id="iconWhatsapp" alt="icon-whatsapp" (mouseenter)="changeIcon('enterW')" (mouseleave)="changeIcon('leaveW')">
        </a>
        <a href="https://www.instagram.com/_multiversoonline/" target="_blank">
            <img src="./assets/icons/i-instagram.svg" id="iconInstagram" alt="icon-instagram" (mouseenter)="changeIcon('enterI')" (mouseleave)="changeIcon('leaveI')">
        </a>
        <a href="https://www.facebook.com/profile.php?id=61562190060668&locale=pt_BR" target="_blank">
            <img src="./assets/icons/i-facebook.svg" id="iconFacebook" alt="icon-facebook" (mouseenter)="changeIcon('enterF')" (mouseleave)="changeIcon('leaveF')">
        </a>
        <!-- <a href="" target="_blank">
            <img src="./assets/icons/i-linkedin.svg" alt="icon-linkedin">
        </a> -->
    </div>
</div>
